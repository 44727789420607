






















































import { Component } from 'vue-property-decorator';
import WorkOrderConfigListService from '@common-src/service/work-order-config-list';
import { BaseComponent } from '@common-src/mixins/base-component';
import WorkOrderFormDesginFieldComponent from '@/pages/dashboard/work-order-setting/work-order-form-design-fields.vue';
import WorkOrderFormDesignUIComponent from '@/pages/dashboard/work-order-setting/work-order-form-design-ui.vue';
import WorkOrderFlowDesignComponent from '@/pages/dashboard/work-order-setting/work-order-flow-design.vue';
import WorkOrderConfigDetailComponent from '@/pages/dashboard/work-order-setting/work-order-config-detail.vue';

@Component({
    components: {
        'field-component': WorkOrderFormDesginFieldComponent,
        'ui-component': WorkOrderFormDesignUIComponent,
        'flow-component': WorkOrderFlowDesignComponent,
        'config-component': WorkOrderConfigDetailComponent
    }
})
export default class WorkOrderFormDesginComponent extends BaseComponent {
    WorkOrderConfigListService = WorkOrderConfigListService;

    serviceId: string = undefined;

    formId: string = undefined;

    processId: string = undefined;

    title: string = '';

    showTag: string = 'fields';

    deployVisible: boolean = false;

    created() {
        this.serviceId = this.$route.params.id;

        this.WorkOrderConfigListService.retrieve(this.serviceId).then(res => {
            this.formId = res.formId;

            this.title = res.serviceName;

            this.processId = res.processDefinitionKey;
        });
    }

    showDeploy() {
        this.deployVisible = true;
    }

    deploy() {
        this.deployVisible = false;
    }

    handleCancel() {
        this.deployVisible = false;
    }
}
